import { Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbComponent } from './Breadcrumb/Breadcrumb';
import { Styled } from './HeaderContainer.style';
import { HeaderContainerProps } from './HeaderContainer.types';
import { WorkspaceSelectorCard } from './WorkspaceSelectorCard/WorkspaceSelectedCard';

export function HeaderContainer({
  pageTitle,
  goBackButtonUrl,
}: HeaderContainerProps): React.ReactElement {
  const history = useHistory();

  const handleGoBack = () => {
    history.push(goBackButtonUrl);
  };

  return (
    <Styled.Wrapper>
      <BreadcrumbComponent />

      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          {goBackButtonUrl && (
            <IconButton variant="tertiary" icon={ArrowLeft} onClick={handleGoBack} />
          )}
          <Heading size="H2">{pageTitle}</Heading>
        </Styled.TitleContainer>
        <WorkspaceSelectorCard />
      </Styled.HeaderContainer>
    </Styled.Wrapper>
  );
}
