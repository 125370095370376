import { Breadcrumb } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

export function BreadcrumbComponent(): React.ReactElement {
  const { formatMessage } = useIntl();

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x: string) => x);

  const routeNames = {
    reports: formatMessage({ id: 'BREADCRUMBS.REPORTS' }),
    'main-page': formatMessage({ id: 'BREADCRUMBS.MAIN_PAGE' }),
  };

  return (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem href="/" />
      {pathnames.map((value: keyof typeof routeNames, index: number) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isCurrent = index === pathnames.length - 1;
        return (
          <Breadcrumb.Item key={to} href={to} isCurrentPage={isCurrent}>
            {routeNames[value]}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb.Root>
  );
}
