import { Card as _Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CardContainer = styled(_Card, {
  width: '100%',
  minHeight: '116px',
  padding: '$6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: '$1',

  '&:hover': {
    backgroundColor: '$neutral20',
    transition: 'background-color 0.3s ease',
  },
  variants: {
    isSelected: {
      true: {
        backgroundColor: 'rgb(230, 245, 253)',
        '&:hover': {
          backgroundColor: 'rgb(225, 240, 248)',
        },
      },
    },
  },
});

const Styled = {
  CardContainer,
};

export default Styled;
