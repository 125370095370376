import {
  ANALYTICS_ADMIN_GROUP,
  FEATURE_ACTIVATION_MANAGER,
  FEATURE_SETUP_MANAGER,
} from 'Assets/Constants';
import MFERoutes, { generateExternalReportsPath, generateReportsPath } from 'Config/MFERoutes';
import ExternalReport from 'Domain/ExternalReport';
import MenuItem from 'Domain/MenuItem';
import { isManager } from 'Domain/User';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import { SidebarItem } from 'admin-portal-shared-services';

import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import iconMap from 'Utils/iconMap/iconMap';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icons } from './icons';

export default function useSidebarItems({
  reportsMenuItems,
  externalReports,
}: {
  reportsMenuItems?: MenuItem[];
  externalReports?: ExternalReport[];
}): SidebarItem[] {
  const { locale, defaultLocale, formatMessage } = useIntl();
  const { triggerMenuInteraction } = useAppEvents();
  const { vendorId, selectedCountry } = useSelectedVendor();
  const loggedUserType = LoggedUserStore.getState();
  const globalOverviewSlug = 'global-overview';
  const userGroups = useUserGroups();
  const userScopes = useUserScopes();

  const hasAnalyticsAdminPermission = hasAdminPermission(userGroups, ANALYTICS_ADMIN_GROUP);
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const hasActivationPermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_MANAGER);

  const hasMenuItems = reportsMenuItems && reportsMenuItems.length > 0;
  return useMemo(() => {
    let items: SidebarItem[] = [];

    const onPageChange = (menuCategory: string) => () => {
      triggerMenuInteraction(menuCategory);
    };

    if (isManager() && !hasMenuItems) {
      items.push({
        id: 'get-started',
        icon: Icons.World,
        path: MFERoutes.GET_STARTED,
        title: formatMessage({ id: 'SIDEBAR.GET_START' }),
      });
    }

    /* istanbul ignore if */
    if (reportsMenuItems) {
      reportsMenuItems.forEach((menuItem) =>
        items.push({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          icon: () => iconMap[menuItem.iconString] || Icons.BarChart,
          id: menuItem.slug,
          path: generateReportsPath({
            vendorId,
            country: selectedCountry,
            menuItem: menuItem.slug,
          }),
          title: menuItem.getTitle(locale, defaultLocale),
          onPageChange: onPageChange(menuItem.trackingId),
        })
      );
    }

    if (externalReports && externalReports.length > 0) {
      if (hasMenuItems) {
        const globalOverviewTab = items.find((report) => report.id === globalOverviewSlug);
        /* istanbul ignore else */
        if (globalOverviewTab) {
          items = items.filter((report) => report.id !== globalOverviewSlug);
        }
      }
      items.push({
        icon: Icons.ExternalLink,
        id: 'external-reports',
        path: generateExternalReportsPath({ vendorId, country: selectedCountry }),
        title: formatMessage({ id: 'EXTERNAL_REPORTS_PAGE.TITLE' }),
        onPageChange: onPageChange('ADDITIONAL_REPORTS'),
      });
    }

    /* istanbul ignore if */
    if (hasAnalyticsAdminPermission) {
      items.push({
        icon: Icons.UserSettings,
        id: 'analytics-admin',
        path: MFERoutes.ANALYTICS_ADMIN,
        title: formatMessage({ id: 'SIDEBAR.ANALYTICS_ADMIN' }),
        onPageChange: onPageChange('ANALYTICS_ADMIN'),
      });
    }

    /* istanbul ignore if */
    if (hasActivationPermission || hasSetupPermission) {
      items.push({
        icon: Icons.Store,
        id: 'vendors-list',
        path: MFERoutes.REPORTS_VENDOR_MANAGEMENT,
        title: formatMessage({ id: 'SIDEBAR.FEATURE_ACTIVATION' }),
        onPageChange: onPageChange('VENDORS_LIST'),
      });
    }
    /* istanbul ignore if */
    if (hasSetupPermission) {
      items.push(
        {
          icon: Icons.List,
          id: 'themes-list',
          path: MFERoutes.REPORTS_MANAGEMENT,
          title: formatMessage({ id: 'SIDEBAR.FEATURE_SETUP' }),
          onPageChange: onPageChange('THEMES_LIST'),
        },
        {
          icon: Icons.Store2Edit,
          id: 'vendors-registration',
          path: MFERoutes.VENDOR_REGISTRATION,
          title: formatMessage({ id: 'SIDEBAR.PARTNER_REGISTRATION' }),
          onPageChange: onPageChange('PARTNER_REGISTRATION'),
        }
      );
    }

    return items;
  }, [
    reportsMenuItems,
    externalReports,
    loggedUserType.loggedUserType,
    triggerMenuInteraction,
    formatMessage,
    vendorId,
    selectedCountry,
    locale,
    defaultLocale,
  ]);
}
