import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
});

export const Styled = {
  Container,
};
