import React from 'react';

import { Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import Styled from './WorkspaceCard.style';
import { WorkspaceCardProps } from './WorkspaceCard.types';

export function WorkspaceCard({
  title,
  segments,
  isSelected,
}: WorkspaceCardProps): React.ReactElement {
  const { formatMessage } = useIntl();

  // eslint-disable-next-line max-params
  function formatPlural(value: number, singular: string, plural: string): string {
    return `${value} ${value === 1 ? singular : plural}`;
  }

  return (
    <Styled.CardContainer border="medium" elevated="minimal" isSelected={isSelected}>
      <Heading size="H4">{title}</Heading>

      <Paragraph size="small" css={{ color: '$interfaceLabelSecondary' }}>
        {formatPlural(
          segments,
          formatMessage({ id: 'WORKSPACE_CARD.SINGULAR_TEXT' }),
          formatMessage({ id: 'WORKSPACE_CARD.PLURAL_TEXT' })
        )}
      </Paragraph>
    </Styled.CardContainer>
  );
}
