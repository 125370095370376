import { Heading, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { CardSectionProps } from './CardSection.d';
import { Styled } from './CardSection.style';

function CardSection({
  title,
  subtitle,
  hasRedirect,
  redirectText,
  pagePath,
  children,
  paddingBottom = true,
}: Readonly<CardSectionProps>): React.ReactElement {
  return (
    <Styled.Card border="medium" elevated="small" paddingBottom={paddingBottom}>
      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          <Heading size="H3">{title}</Heading>
          {hasRedirect && (
            <Styled.LinkContainer href={pagePath}>{redirectText}</Styled.LinkContainer>
          )}
        </Styled.TitleContainer>
        <Paragraph>{subtitle}</Paragraph>
      </Styled.HeaderContainer>
      {children}
    </Styled.Card>
  );
}

export default CardSection;
