import MessageMap from 'I18n/i18n';

const es419: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Only English option available',
  },

  MAIN_PAGE: {
    TITLE: 'Data & Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'Business transformation guide',
      DESCRIPTION: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Workspaces',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all workspaces',
    },
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'segment',
    PLURAL_TEXT: 'segments',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
    MAIN_PAGE: 'Data & Analytics',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Datos y análisis',
    REPORTS_PAGE_VENDOR: 'Proveedor',
    CONTENT_REPORTS_TAB_VIEW:
      'Mida su rendimiento en todos los pedidos entregados dentro del período seleccionado',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Descripción general global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Algó falló',
    CONTAINER_ERROR_LABEL:
      'Lo lamentamos, estamos teniendo dificultades técnicas. Por favor, vuelve a intentarlo.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Descargar CSV',
    DESCRIPTION: 'Seleccionar el período para la descarga.',
    YEAR_LABEL: 'Año',
    MONTH_LABEL: 'Mes',
    NO_INFO_AVAILABLE:
      'No hay información en relación al período seleccionado. Por favor, intenta con fechas diferentes.',
    CANCEL: 'Cancelar',
    DOWNLOAD: 'Descargar',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Datos del negocio de un nivel superior',
    FIRST_DESCRIPTION:
      'Obtené acceso a exportar CSVs y muchas otras funcionalidades para mejorar tu análisis.',
    BUTTON_BECOME: 'Transfórmate en un Gold Partner',
    BUTTON_LATER: 'Más tarde',
    MODAL_STYLE: 'ES',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Exportar datos',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Tu versión de prueba vencerá pronto',
    FIRST_DESCRIPTION:
      'El archivo CSV que estás a punto de descargar es cortesía de nuestro programa de membresía.',
    SECOND_DESCRIPTION:
      'Para mantener el acceso a datos especiales, hechos a la medida de tu empresa, tendrás que hacer el upgrade hacia Gold Partner.',
    BUTTON: 'DESCARGAR ARCHIVO',
    NO_INFO_AVAILABLE: 'No hay información en relación al período.',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Cómo acceder a un archivo CSV',
    DESCRIPTION:
      '¿No estás familiarizado con los archivos CSV? Aquí una guía de paso por paso acerca de cómo utilizar un archivo CSV.',
    LINK: 'Mostrar guía para archivos CSV',
    PROCEED_BUTTON: 'Proceder y descargar',
  },

  GET_STARTED: {
    LOADING: 'Cargando',
    TITLE: 'Comenzar',
    PARAGRAPH: 'Seleccionar un proveedor para ver su información y datos.',
    NEXT: 'Próximo',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Cambiar',
    TITLE: 'Estas viendo',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Abierto',
    CLEAR: 'Limpiar',
  },
  MONTH: {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  },

  COMMON: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
  },
  LOADING: {
    TITLE: 'Cargando la página',
    PARAGRAPH: 'Estamos preparando la página para ti.',
  },
  SELECT_VENDOR: {
    TITLE: 'Seleccionar un proveedor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Acceso denegado!',
    ERROR_DESCRIPTION: 'Parece que no tienes permiso para acceder a esta página',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Algo salió mal. Por favor, inténtalo de nuevo más tarde.',
    PREPARING_DOWNLOAD: 'Preparando archivo CSV para descargar.',
    SUCCESS_DOWNLOAD: 'Descarga completada con éxito.',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Transfórmate en premium',
    TITLE_NORMAL: `Tenemos un plan\nque es perfecto para`,
    TITLE_HIGHLIGHTED: 'tu negocio',
    SUBTITLE: 'Fortalece tus análisis con la membresía indicada para tu empresa.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'Para negocios que están comenzando en el mundo del comercio digital.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Plan actual',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'Para negocios con equipos de inteligencia de datos avanzada, que quieren invertir en audiencias objetivo.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Entra en contacto',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Métricas',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Unidades entregadas',
        ORDERS_DELIVERED: 'Pedidos entregados',
        BUYERS: 'Compradores',
        UNIT_DELIVERED_BY_PRODUCT: 'Unidades entregadas por producto',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Unidades entregadas por Ciudad y Estado',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Unidades vendidas por centro de distribución',
        TOP_5_PRODUCTS: 'Top 5 Productos',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Evolución mensual de pedidos',
        REVENUE: 'Facturación',
        AVERAGE_REVENUE_PER_ORDER: 'Facturación promedio por pedido',
        AVERAGE_REVENUE_PER_BUYER: 'Facturación promedio por Comprador',
        REVENUE_BY_REGION: 'Facturación por región',
        MONTHLY_SALES_REVENUE: 'Receita mensal de vendas',
        CURRENT_MONTH_VS_LAST_MONTH: 'Mes actual vs. mes pasado',
        ORDERS_VS_REVENUE_STATE: 'Pedidos x facturación/estado',
        ORDERS_VS_REVENUE_SEGMENT: 'Pedidos x facturación/segmento',
        ORDERS_VS_REVENUE_PRODUCT: 'Pedidos x facturación/producto',
        REVENUE_BY_PRODUCT: 'Facturación por producto',
        REVENUE_BY_SEGMENT: 'Facturación por segmento',
        REVENUE_BY_CHANNEL: 'Facturación por canal',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Fecha',
        GROSS_VALUE: 'Valor Bruto',
        REGION_STATE_AND_CITY: 'Región (Estado y Ciudad)',
        DISTRIBUTION_CENTER: 'Centro de distribución',
        SEGMENT: 'Segmento',
        SUB_SEGMENT: 'Subsegmento',
        CHANNEL: 'Canal',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Datos del negocio de un',
    TITLE_NEXT_LEVEL: 'nivel superior',
    DESCRIPTION:
      'Con nuestros datos premium, podrás descubrir poderosos análisis que desplegarán todo el potencial de tu empresa.',
    BUTTON: 'Transfórmate en un Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusivo para Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS:
      '¡Solicitud enviada con éxito! Nuestro equipo se pondrá en contacto contigo pronto.',
    TOAST_ERROR: 'Algo falló. Por favor, vuelve a intentarlo.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brasil',
    CL: 'Chile',
    CO: 'Colombia',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    ID: 'Indonesia',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Perú',
    PH: 'Filipinas',
    PY: 'Paraguay',
    ZA: 'Sudáfrica',
    CH: 'Switzerland',
    GLOBAL_OVERVIEW: 'Descripción general global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Seleccionar país',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Seleccionar modelo de negocio',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Seleccionar proveedor',
    LOADING: 'Cargando...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Recuperando los datos…',
      BODY: 'Paso 1 de 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Cargando informe…',
      BODY: 'Paso 2 de 3',
    },

    LOADING_DATA: {
      TITLE: 'Ya casi estamos',
      BODY: 'Paso 3 de 3 ',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    FEATURE_SETUP: 'Feature Setup',
    FEATURE_ACTIVATION: 'Feature Activation',
    PARTNER_REGISTRATION: 'Partner Registration',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Informes y documentación adicionales',
    DESCRIPTION: 'Accede a informes adicionales de otros productos BEES.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Entendiendo nuestros KPI',
    SUBTITLE:
      'Esta página explica cuáles son nuestros KPI (indicadores clave de desempeño) y cómo se calculan. Para encontrar un KPI específico, use la barra de búsqueda o navegue por las pestañas a continuación.',
    COLUMNS: {
      NAME: 'Nombre del KPI',
      DESCRIPTION: 'Descripción',
      METHODOLOGY: 'Metodología de cálculo',
    },
    EMPTY_STATE: 'Oops, sorry! No results found.',
    SEARCH_FIELD: 'Buscar por KPI o palabra clave',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'No se encontraron resultados.',
      EMPTY_STATE_TEXT: 'Verifique su ortografía o pruebe usar diferentes palabras de búsqueda.',
      COUNT_RESULT_MESSAGE: ' resultados para ',
      UNIT_RESULT_MESSAGE: '1 resultado para ',
    },
  },

  REPORT_ADMIN_PAGE: {
    DATA_ANALYTICS_TITLE: 'Data & Analytics Setup Tool',
    THEME_TITLE: 'Theme',
    THEME_SUBTITLE: 'These themes will be used to create the UX menu navigation options',
    FEATURE_SETUP: 'Feature Setup',
    SOMETHING_WRONG: 'Something went wrong. Please try again.',

    REPORT_DETAILS: {
      HEADER: {
        TITLE: 'Title',
        TRACKING_ID: 'Tracking ID',
        TAB_ORDER: 'Tab order',
        WORKSPACE_ID: 'Workspace ID',
        REPORT_ID: 'Report ID',
      },
      RULES: {
        TITLE: 'Rules',
        ADD_NEW: 'Add new rule',
      },
      TABLE: {
        MANUFACTURER: 'Manufacturer',
        SELLER: 'Seller',
      },
    },
  },

  REPORT_VENDOR_ADMIN_PAGE: {
    VENDOR_MANAGEMENT: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        DTAAS_REPORTS: 'DTaaS Reports',
        SALES_MANAGER_REPORTS: 'Sales Manager Reports',
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
    VENDOR_MANAGEMENT_DETAILS: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
  },

  REPORT_DETAILS_PAGE: {
    THEME_NAME: 'Theme name',
    STRUCTURE: 'Structure',
    TRACKING_ID: 'Tracking ID',
    ORDER: 'Order',
    REPORTS: 'Reports',
    REPORT: 'Report',
    WORKSPACE_ID: 'Workspace ID',
    REPORT_ID: 'Report ID',
    RLS_RULE: 'RLS rule',
    ADD_NEW: 'Add new',
  },

  REPORT_NEW_RULE_MODAL: {
    ADD_NEW_RULE: 'Add new rule',
    USER_TYPE: 'User type',
    SELECT_USER_TYPE: 'Select user type',
    BUSINESS_MODEL: 'Business model',
    BUSINESS_PLACEHOLDER: 'Type business model',
    CANCEL: 'Cancel',
    ADD: 'Add',
  },

  DELETE_RULE_MODAL: {
    TITLE: 'Delete rule?',
    DESCRIPTION: 'Are you sure that you want delete the rule?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
  },

  ACTIVATION_PAGE: {
    CLUSTER_SELECT: {
      LABEL: 'Select a cluster',
      GLOBAL_US: 'Global US',
      GLOBAL_EU: 'Global EU',
      SELECTED_CLUSTER: 'Selected cluster',
    },
    THEME_ADD: 'Add new theme',
    REPORT_ADD: 'Add new report',
    ADD_NEW_THEME_MODAL: {
      MODAL_TITLE: 'Add new theme',
      MODAL_EDIT_TITLE: 'Edit theme',
      NAME: 'Name',
      NAME_US: 'Enter a US name',
      NAME_PT: 'Enter a PT name',
      NAME_ES: 'Enter a ES name',
      NAME_ID: 'Enter a ID name',
      NAME_HINT_US: 'en-US',
      NAME_HINT_PT: 'pt-BR',
      NAME_HINT_ES: 'es-419',
      NAME_HINT_ID: 'id-ID',
      ICON: 'Icon',
      ICONS_PLACEHOLDER: 'Select an icon',
      PREVIEW_ICON: 'Preview',
      STRUCTURE: 'Structure',
      STRUCTURE_TAB: 'Tab',
      STRUCTURE_TOGGLE: 'Toggle',
      SLUG: 'Slug',
      SLUG_PLACEHOLDER: 'Enter a slug',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      ORDER: 'Order',
      ORDER_PLACEHOLDER: 'Enter an order',
      CANCEL: 'Cancel',
      CONFIRM: 'Add theme',
      EDIT: 'Edit theme',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
    },
    ADD_NEW_REPORT_MODAL: {
      MODAL_TITLE: 'Add new report',
      MODAL_EDIT_TITLE: 'Edit report informations',
      TITLE: 'Title',
      TITLE_US: 'Enter a US title',
      TITLE_PT: 'Enter a PT title',
      TITLE_ES: 'Enter a ES title',
      TITLE_HINT_US: 'en-US',
      TITLE_HINT_PT: 'pt-BR',
      TITLE_HINT_ES: 'es-419',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      WORKSPACE_ID: 'Workspace ID',
      WORKSPACE_PLACEHOLDER: 'Enter a Workspace ID',
      REPORT_ID: 'Report ID',
      REPORT_ID_PLACEHOLDER: 'Enter a Report ID',
      TAB_ORDER: 'Tab order',
      TAB_ORDER_PLACEHOLDER: 'Enter an Tab order',
      USERTYPE: 'User type',
      USERTYPE_PLACEHOLDER: 'Select user type',
      BUSINESS_MODEL: 'Business model',
      BUSINESS_MODEL_PLACEHOLDER: 'Enter a Business model',
      CANCEL: 'Cancel',
      CONFIRM: 'Add',
      EDIT: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      IS_MANUFACTURER: 'Manufacturer',
    },
  },
  VENDOR_MANAGEMENT_DETAILS_PAGE: {
    PARTNER_AND_MANAGER_TABLE_TITLE: 'DTaaS Reports',
    SALES_MANAGER_TABLE_TITLE: 'Sales Manager Reports',
    TABLE_PARTNER_AND_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
    TABLE_SALES_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
  },
  VENDOR_REGISTRATION_PAGE: {
    TITLE: 'Vendor access',
    SUBTITLE: 'Vendors',
    ADD_BUTTON: 'Add new',
    MODAL: {
      TITLE_ADD_VENDOR: 'Add new vendor',
      TITLE_EDIT_VENDOR: 'Edit vendor',
      NAME_LABEL: 'Name',
      NAME_PLACEHOLDER: 'Enter a name',
      VENDOR_ID_LABEL: 'Vendor Id',
      VENDOR_ID_PLACEHOLDER: 'Enter a vendor Id',
      MANUFACTURER_LABEL: 'Manufacturer',
      CANCEL_BUTTON: 'Cancel',
      ADD_BUTTON: 'Add',
      EDIT_BUTTON: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      TITLE_DELETE_VENDOR: 'Delete Vendor',
      CONFIRMATION_TEXT: 'Are you sure that you want delete the vendor?',
      CONFIRM_BUTTON: 'Confirm',
    },
  },
  THEMES_LIST: {
    TITLE: 'Themes List',
  },
  VENDORS_LIST: {
    TITLE: 'Vendors List',
  },
  VENDORS_UPDATE: {
    SUCCESS: 'Vendor updated successfully.',
    FAIL: 'Vendor update failed',
  },
  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },
  WORKSPACES: {
    TITLE: 'Workspaces',
    ADD_NEW: 'Add new',
    PAGE_TITLE: 'Data & Analytics Admin',
    CARD: {
      ACCESS_RULES: 'Access rules',
      USER: 'User',
      USERS: 'Users',
    },
  },
};

export default es419;
