import { Divider, Paragraph, TextLink } from '@hexa-ui/components';
import PartnerLogo from 'Components/PartnerLogo/PartnerLogo';
import React from 'react';
import { useIntl } from 'react-intl';
import { Styled } from './WorkspaceSelectedCard.style';

const mockValues = {
  vendorId: '0efff2c1-611a-40a3-ad61-982e0b7e7f5d',
  vendorIdName: 'Nestlé',
  workspaceName: 'Global',
};

export function WorkspaceSelectorCard(): React.ReactElement {
  const { formatMessage } = useIntl();

  const handleClick = () => {
    console.log('Change workspace');
  };

  return (
    <Styled.CardContainer border="small" elevated="small">
      <Styled.CardItem>
        <Paragraph>{mockValues.workspaceName}</Paragraph>
      </Styled.CardItem>
      <Divider orientation="vertical" />

      <Styled.CardItem>
        <PartnerLogo css={{ marginRight: '$4' }} vendorId={mockValues.vendorId} />
        <Paragraph
          weight="semibold"
          size="small"
          css={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flexGrow: 1,
            maxWidth: 'max-content',
          }}
        >
          {mockValues.vendorIdName}
        </Paragraph>
      </Styled.CardItem>
      <Divider orientation="vertical" css={{ backgroundColor: 'transparent' }} />

      <Styled.CardItem>
        <TextLink onClick={handleClick} hasUnderline={false}>
          {formatMessage({ id: 'WORKSPACE_SELECTED_CARD.CHANGE' })}
        </TextLink>
      </Styled.CardItem>
    </Styled.CardContainer>
  );
}
