import { EU_CLUSTER_COUNTRIES } from 'Assets/Constants';
import { EnvContext } from 'Components/EnvProvider';
import useUserSupportedCountries from 'Hooks/useUserSupportedCountries/useUserSupportedCountries';
import { useContext } from 'react';

export default function useSupportedCountries(): string[] {
  const userSupportedCountries = useUserSupportedCountries();

  const { supportedCountries: envApplicationSupportedCountries } = useContext(EnvContext);
  if (envApplicationSupportedCountries == null) {
    return userSupportedCountries ?? [];
  }

  const applicationSupportedCountries = envApplicationSupportedCountries
    .split(',')
    .concat(EU_CLUSTER_COUNTRIES);

  return (
    userSupportedCountries?.filter((country) => applicationSupportedCountries.includes(country)) ??
    []
  );
}
