import { MFE_NAME } from 'Assets/Constants';
import { isGlobalPartner, isManager, isMultiVendor } from 'Domain/User';
import { generatePath } from 'react-router-dom';

const MFERoutes = {
  MAIN_PAGE: `${MFE_NAME}/main-page`,
  GET_STARTED: `${MFE_NAME}/get-started`,
  BECOME_PREMIUM: `${MFE_NAME}/become-premium`,
  REPORTS_MANAGER: `${MFE_NAME}/:country?/:vendorId?/:menuItem?/:tab?`,
  REPORTS_PARTNER: `${MFE_NAME}/:menuItem?/:tab?`,
  EXTERNAL_REPORTS_MANAGER: `${MFE_NAME}/:country/:vendorId/external-reports`,
  EXTERNAL_REPORTS_PARTNER: `${MFE_NAME}/external-reports`,
  HOME: MFE_NAME,
  KPI_DOCUMENTATION_MANAGER: `${MFE_NAME}/:country/:vendorId/docs/:menuItem?`,
  KPI_DOCUMENTATION_PARTNER: `${MFE_NAME}/docs/:menuItem?`,
  REPORTS_MANAGEMENT: `${MFE_NAME}/reports-admin`,
  REPORTS_VENDOR_MANAGEMENT: `${MFE_NAME}/vendor-management`,
  VENDOR_REGISTRATION: `${MFE_NAME}/vendor-registration`,
  ANALYTICS_ADMIN: `/analytics-admin`,
};

export const getReportsRoute = (): string =>
  isManager() ? MFERoutes.REPORTS_MANAGER : MFERoutes.REPORTS_PARTNER;

export const generateReportsPath = ({
  vendorId,
  menuItem,
  tab,
  country,
}: {
  vendorId?: string;
  menuItem?: string;
  tab?: string;
  country?: string;
}): string => generatePath(getReportsRoute(), { vendorId, menuItem, tab, country });

export const getExternalReportsRoute = (): string =>
  isManager() || isMultiVendor() || isGlobalPartner()
    ? MFERoutes.EXTERNAL_REPORTS_MANAGER
    : MFERoutes.EXTERNAL_REPORTS_PARTNER;

export const generateExternalReportsPath = ({
  country,
  vendorId,
}: {
  country?: string;
  vendorId?: string;
}): string => generatePath(getExternalReportsRoute(), { country, vendorId });

export const getKPIDocumentationRoute = (): string =>
  isManager() || isMultiVendor() || isGlobalPartner()
    ? MFERoutes.KPI_DOCUMENTATION_MANAGER
    : MFERoutes.KPI_DOCUMENTATION_PARTNER;

export const generateKPIDocumentationPath = ({
  country,
  vendorId,
  menuItem,
}: {
  country?: string;
  vendorId?: string;
  menuItem?: string;
}): string => generatePath(getKPIDocumentationRoute(), { country, vendorId, menuItem });

export default MFERoutes;
