import MessageMap from 'I18n/i18n';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Only English option available',
  },

  MAIN_PAGE: {
    TITLE: 'Data & Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'Business transformation guide',
      DESCRIPTION: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Workspaces',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all workspaces',
    },
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'segment',
    PLURAL_TEXT: 'segments',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
    MAIN_PAGE: 'Data & Analytics',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Dados e análises',
    REPORTS_PAGE_VENDOR: 'Fornecedor',
    CONTENT_REPORTS_TAB_VIEW:
      'Avalie seu desempenho em todos os pedidos entregues dentro do período selecionado',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Visão geral global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Algo deu errado',
    CONTAINER_ERROR_LABEL: 'Desculpe, estamos com problemas técnicos. Por favor, tente novamente.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Exportar CSV',
    DESCRIPTION: 'Selecione o período para fazer o download.',
    YEAR_LABEL: 'Ano',
    MONTH_LABEL: 'Mês',
    NO_INFO_AVAILABLE:
      'Não há informações para o período selecionado. Por favor, tente uma data diferente.',
    CANCEL: 'Cancelar',
    DOWNLOAD: 'Baixar',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Insights sobre o seu negócio de um jeito inovador',
    FIRST_DESCRIPTION:
      'Exporte arquivos CSV e tenha acesso a outros recursos que vão melhorar suas análises.',
    BUTTON_BECOME: 'Torne-se um Gold Partner',
    BUTTON_LATER: 'Depois',
    MODAL_STYLE: 'BR',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Exportar dados',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Seu teste gratuito termina em breve',
    FIRST_DESCRIPTION:
      'O arquivo CSV que você está prestes a baixar é uma cortesia do nosso programa Gold Partner.',
    SECOND_DESCRIPTION:
      'Para continuar a ter acesso a insights exclusivos sobre o seu negócio, você precisará fazer um upgrade para o programa Gold Partner.',
    BUTTON: 'BAIXAR ARQUIVO',
    NO_INFO_AVAILABLE: 'Não há informações para o período.',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Como acessar um arquivo CSV',
    DESCRIPTION:
      'Não está familiarizado com arquivos CSV? Confira um guia passo a passo sobre como usá-lo!',
    LINK: 'Mostrar guia para arquivos CSV',
    PROCEED_BUTTON: 'Prossiga para baixar',
  },

  GET_STARTED: {
    LOADING: 'Carregando',
    TITLE: 'Começar',
    PARAGRAPH: 'Selecione um fornecedor para descobrir seus dados e insights.',
    NEXT: 'Próximo',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Alterar',
    TITLE: 'Você está vendo',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Abrir',
    CLEAR: 'Limpar',
  },

  MONTH: {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  },

  COMMON: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
  },
  LOADING: {
    TITLE: 'Carregando',
    PARAGRAPH: 'Estamos carregando a página para você.',
  },
  SELECT_VENDOR: {
    TITLE: 'Selecionar um fornecedor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Acesso negado!',
    ERROR_DESCRIPTION: 'Parece que você não tem permissão para acessar esta pagina.',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Algo deu errado. Por favor, tente novamente.',
    PREPARING_DOWNLOAD: 'Preparando o arquivo CSV para download.',
    SUCCESS_DOWNLOAD: 'Download concluído com sucesso.',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Torne-se um premium',
    TITLE_NORMAL: `Temos um plano\nperfeito para`,
    TITLE_HIGHLIGHTED: 'o seu negócio',
    SUBTITLE: 'Potencialize suas análises com o programa de benefícios ideal para o seu negócio.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'Para negócios que estão começando no mundo do mercado digital.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Plano atual',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'Para negócios com times experientes em inteligência de dados e que desejam investir em audiências específicas.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Entre em contato',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Métricas',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Unidades entregues',
        ORDERS_DELIVERED: 'Pedidos entregues',
        BUYERS: 'Compradores',
        UNIT_DELIVERED_BY_PRODUCT: 'Unidades entregues por produto',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Unidades entregues por Cidade e Estado',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Unidades Vendidas por Centro de Distribuição',
        TOP_5_PRODUCTS: 'Top 5 Produtos',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Evolução mensal de pedidos',
        REVENUE: 'Receita',
        AVERAGE_REVENUE_PER_ORDER: 'Média de Faturamento por Pedido',
        AVERAGE_REVENUE_PER_BUYER: 'Média de Faturamento por Comprador',
        REVENUE_BY_REGION: 'Receita por região',
        MONTHLY_SALES_REVENUE: 'Receita mensal de vendas',
        CURRENT_MONTH_VS_LAST_MONTH: 'Mês atual x mês passado',
        ORDERS_VS_REVENUE_STATE: 'Pedidos x receita/estado',
        ORDERS_VS_REVENUE_SEGMENT: 'Pedidos x receita/segmento',
        ORDERS_VS_REVENUE_PRODUCT: 'Pedidos x receita/produto',
        REVENUE_BY_PRODUCT: 'Receita por produto',
        REVENUE_BY_SEGMENT: 'Receita por segmento',
        REVENUE_BY_CHANNEL: 'Faturamento por Canal',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Data',
        GROSS_VALUE: 'Valor Bruto',
        REGION_STATE_AND_CITY: 'Região (Estado e Cidade)',
        DISTRIBUTION_CENTER: 'Centro de Distribuição',
        SEGMENT: 'Segmento',
        SUB_SEGMENT: 'Sub-Segmento',
        CHANNEL: 'Canal',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Insights sobre o seu negócio de um',
    TITLE_NEXT_LEVEL: 'jeito inovador',
    DESCRIPTION:
      'Com os nossos insights premium, você tem acesso a análises poderosas que vão expandir o potencial do seu negócio.',
    BUTTON: 'Torne-se um Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusivo para Parceiros Gold',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS:
      'Solicitação enviada com sucesso! Nossa equipe entrará em contato com você em breve.',
    TOAST_ERROR: 'Algo deu errado. Tente novamente mais tarde.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolívia',
    BR: 'Brasil',
    CL: 'Chile',
    CO: 'Colômbia',
    DO: 'República Dominicana',
    EC: 'Equador',
    ID: 'Indonésia',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Peru',
    PH: 'Filipinas',
    PY: 'Paraguai',
    ZA: 'África do Sul',
    CH: 'Switzerland',
    GLOBAL_OVERVIEW: 'Visão geral global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Selecionar país',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Selecionar modelo de negócio',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Selecionar fornecedor',
    LOADING: 'Carregando...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Buscando os dados...',
      BODY: 'Etapa 1 de 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Carregando o relatório...',
      BODY: 'Etapa 2 de 3',
    },

    LOADING_DATA: {
      TITLE: 'Estamos quase lá',
      BODY: 'Etapa 3 de 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    FEATURE_SETUP: 'Feature Setup',
    FEATURE_ACTIVATION: 'Feature Activation',
    PARTNER_REGISTRATION: 'Partner Registration',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Relatórios e documentação adicionais',
    DESCRIPTION: 'Acesse relatórios adicionais de outros produtos BEES.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Entendendo nossos KPIs',
    SUBTITLE:
      'Esta página explica quais são os nossos KPIs (principais indicadores de desempenho) e como eles são calculados. Para encontrar um KPI específico, use a barra de pesquisa ou navegue pelas abas abaixo.',
    COLUMNS: {
      NAME: 'Nome do KPI',
      DESCRIPTION: 'Descrição',
      METHODOLOGY: 'Metodologia de cálculo',
    },
    EMPTY_STATE: 'Oops, sorry! No results found.',
    SEARCH_FIELD: 'Pesquisar por KPI ou palavra-chave',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'Nenhum resultado encontrado.',
      EMPTY_STATE_TEXT: 'Verifique a digitação ou tente usar palavras-chave diferentes.',
      COUNT_RESULT_MESSAGE: ' resultados para ',
      UNIT_RESULT_MESSAGE: '1 resultado para ',
    },
  },

  REPORT_ADMIN_PAGE: {
    DATA_ANALYTICS_TITLE: 'Data & Analytics Setup Tool',
    THEME_TITLE: 'Theme',
    THEME_SUBTITLE: 'These themes will be used to create the UX menu navigation options',
    FEATURE_SETUP: 'Feature Setup',
    SOMETHING_WRONG: 'Something went wrong. Please try again.',

    REPORT_DETAILS: {
      HEADER: {
        TITLE: 'Title',
        TRACKING_ID: 'Tracking ID',
        TAB_ORDER: 'Tab order',
        WORKSPACE_ID: 'Workspace ID',
        REPORT_ID: 'Report ID',
      },
      RULES: {
        TITLE: 'Rules',
        ADD_NEW: 'Add new rule',
      },
      TABLE: {
        MANUFACTURER: 'Manufacturer',
        SELLER: 'Seller',
      },
    },
  },

  REPORT_VENDOR_ADMIN_PAGE: {
    VENDOR_MANAGEMENT: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        DTAAS_REPORTS: 'DTaaS Reports',
        SALES_MANAGER_REPORTS: 'Sales Manager Reports',
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
    VENDOR_MANAGEMENT_DETAILS: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
  },

  REPORT_DETAILS_PAGE: {
    THEME_NAME: 'Theme name',
    STRUCTURE: 'Structure',
    TRACKING_ID: 'Tracking ID',
    ORDER: 'Order',
    REPORTS: 'Reports',
    REPORT: 'Report',
    WORKSPACE_ID: 'Workspace ID',
    REPORT_ID: 'Report ID',
    RLS_RULE: 'RLS rule',
    ADD_NEW: 'Add new',
  },

  REPORT_NEW_RULE_MODAL: {
    ADD_NEW_RULE: 'Add new rule',
    USER_TYPE: 'User type',
    SELECT_USER_TYPE: 'Select user type',
    BUSINESS_MODEL: 'Business model',
    BUSINESS_PLACEHOLDER: 'Type business model',
    CANCEL: 'Cancel',
    ADD: 'Add',
  },

  DELETE_RULE_MODAL: {
    TITLE: 'Delete rule?',
    DESCRIPTION: 'Are you sure that you want delete the rule?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
  },

  ACTIVATION_PAGE: {
    CLUSTER_SELECT: {
      LABEL: 'Select a cluster',
      GLOBAL_US: 'Global US',
      GLOBAL_EU: 'Global EU',
      SELECTED_CLUSTER: 'Selected cluster',
    },
    THEME_ADD: 'Add new theme',
    REPORT_ADD: 'Add new report',
    ADD_NEW_THEME_MODAL: {
      MODAL_TITLE: 'Add new theme',
      MODAL_EDIT_TITLE: 'Edit theme',
      NAME: 'Name',
      NAME_US: 'Enter a US name',
      NAME_PT: 'Enter a PT name',
      NAME_ES: 'Enter a ES name',
      NAME_ID: 'Enter a ID name',
      NAME_HINT_US: 'en-US',
      NAME_HINT_PT: 'pt-BR',
      NAME_HINT_ES: 'es-419',
      NAME_HINT_ID: 'id-ID',
      ICON: 'Icon',
      ICONS_PLACEHOLDER: 'Select an icon',
      PREVIEW_ICON: 'Preview',
      STRUCTURE: 'Structure',
      STRUCTURE_TAB: 'Tab',
      STRUCTURE_TOGGLE: 'Toggle',
      SLUG: 'Slug',
      SLUG_PLACEHOLDER: 'Enter a slug',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      ORDER: 'Order',
      ORDER_PLACEHOLDER: 'Enter an order',
      CANCEL: 'Cancel',
      CONFIRM: 'Add theme',
      EDIT: 'Edit theme',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
    },
    ADD_NEW_REPORT_MODAL: {
      MODAL_TITLE: 'Add new report',
      MODAL_EDIT_TITLE: 'Edit report informations',
      TITLE: 'Title',
      TITLE_US: 'Enter a US title',
      TITLE_PT: 'Enter a PT title',
      TITLE_ES: 'Enter a ES title',
      TITLE_HINT_US: 'en-US',
      TITLE_HINT_PT: 'pt-BR',
      TITLE_HINT_ES: 'es-419',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      WORKSPACE_ID: 'Workspace ID',
      WORKSPACE_PLACEHOLDER: 'Enter a Workspace ID',
      REPORT_ID: 'Report ID',
      REPORT_ID_PLACEHOLDER: 'Enter a Report ID',
      TAB_ORDER: 'Tab order',
      TAB_ORDER_PLACEHOLDER: 'Enter an Tab order',
      USERTYPE: 'User type',
      USERTYPE_PLACEHOLDER: 'Select user type',
      BUSINESS_MODEL: 'Business model',
      BUSINESS_MODEL_PLACEHOLDER: 'Enter a Business model',
      CANCEL: 'Cancel',
      CONFIRM: 'Add',
      EDIT: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      IS_MANUFACTURER: 'Manufacturer',
    },
  },
  VENDOR_MANAGEMENT_DETAILS_PAGE: {
    PARTNER_AND_MANAGER_TABLE_TITLE: 'DTaaS Reports',
    SALES_MANAGER_TABLE_TITLE: 'Sales Manager Reports',
    TABLE_PARTNER_AND_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
    TABLE_SALES_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
  },
  VENDOR_REGISTRATION_PAGE: {
    TITLE: 'Vendor access',
    SUBTITLE: 'Vendors',
    ADD_BUTTON: 'Add new',
    MODAL: {
      TITLE_ADD_VENDOR: 'Add new vendor',
      TITLE_EDIT_VENDOR: 'Edit vendor',
      NAME_LABEL: 'Name',
      NAME_PLACEHOLDER: 'Enter a name',
      VENDOR_ID_LABEL: 'Vendor Id',
      VENDOR_ID_PLACEHOLDER: 'Enter a vendor Id',
      MANUFACTURER_LABEL: 'Manufacturer',
      CANCEL_BUTTON: 'Cancel',
      ADD_BUTTON: 'Add',
      EDIT_BUTTON: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      TITLE_DELETE_VENDOR: 'Delete Vendor',
      CONFIRMATION_TEXT: 'Are you sure that you want delete the vendor?',
      CONFIRM_BUTTON: 'Confirm',
    },
  },
  THEMES_LIST: {
    TITLE: 'Themes List',
  },
  VENDORS_LIST: {
    TITLE: 'Vendors List',
  },
  VENDORS_UPDATE: {
    SUCCESS: 'Vendor updated successfully.',
    FAIL: 'Vendor update failed',
  },
  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },
  WORKSPACES: {
    TITLE: 'Workspaces',
    ADD_NEW: 'Add new',
    PAGE_TITLE: 'Data & Analytics Admin',
    CARD: {
      ACCESS_RULES: 'Access rules',
      USER: 'User',
      USERS: 'Users',
    },
  },
};

export default ptBR;
