import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Card = styled(HexaCard, {
  padding: '24px',
  gap: '8px',
  variants: {
    paddingBottom: {
      true: {
        paddingBottom: '24px',
      },
      false: {
        paddingBottom: '0',
      },
    },
  },
});

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',

  '& > *:nth-child(2)': {
    fontWeight: '400',
    fontSize: '14px',
    opacity: '56%',
  },
});

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const LinkContainer = styled('a', {
  fontFamily: 'Work Sans',
  fontSize: '14px',
  fontWeight: '500',
  color: '#0F7FFA',
  cursor: 'pointer',
  textDecoration: 'none',
  lineHeight: '16px',
});

export const Styled = {
  Card,
  HeaderContainer,
  TitleContainer,
  LinkContainer,
};
