import { styled } from "@hexa-ui/theme";

const LinkContainer = styled("a", {
    fontFamily: "Work Sans",
    fontSize: "14px",
    fontWeight: "500",
    color: "#0F7FFA",
    cursor: "pointer",
    textDecoration: "none",
    lineHeight: "16px",
    margin: "8px 0px 36px 0px",
  });

  const ImageAndTextWrapper = styled("div", {
    height: "130px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& > *:nth-child(2)": {
      width: "70%",
      fontFamily: "Barlow",
      fontSize: "20px",
      fontWeight: "600",
      lineHeight: "24px",
      textAlign: "left",
      opacity: "56%",
    }
  });


  export const Styled = {
    LinkContainer,
    ImageAndTextWrapper,
  }